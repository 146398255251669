import './style.scss'
import{ init } from '@emailjs/browser';
import * as emailjs from '@emailjs/browser';
init("KR7iofTNCltNUvW1l");

var slider = tns({
    container: '.my-slider',
    "items": "3",
    "fixedWidth": 350,
    "nav": false,
    "speed": 400,
    "loop": true,
    "rewind": false,
    controlsContainer: '.controls__wrapper',
    responsive: {
        0: {
            "fixedWidth": 210,
            "items": 1,
            "gutter": 30
        },
        450: {
            "fixedWidth": 330,
            "items": 1,
            "gutter": 0
        },
        720: {
            "fixedWidth": 330,
            "items": 2
        },
        1105: {
            "fixedWidth": 350,
            "items": 3
        }
    }
});


const words = Array.from(document.querySelectorAll('.word__item'))

let word = words[0];

const interval = setInterval( () => {
    word.classList.remove('rotated')
    setTimeout(() => {
        word.classList.add('rotated')
        setTimeout(()=> {
            word.classList.remove('rotated')
            word = words[words.indexOf(word) + 1]
            if (words.indexOf(word) === -1) {
                word = words[0]
            }
        }, 1500)
    }, 1500)
}, 3000)

const popup = document.querySelector('.popup');
const popupContent = document.querySelector('.popup__content');

const showPopup = () => {
    popup.style.opacity = '1';
    popup.style.visibility = 'visible';
    setTimeout(function () {
        popupContent.classList.add('popup_active');
    }, 300);
}

const closePopup = () => {
    popup.style.opacity = '0';
    popup.style.visibility = 'hidden';
    popupContent.classList.remove('popup_active');
}

const popupBtn = document.getElementById('open-popup__btn');
const cross = document.getElementById('cross');
const popupSubmit = document.getElementById('popup__submit');
const checkbox = document.getElementById('checkbox')


checkbox.onchange = function () {
    popupSubmit.disabled = !checkbox.checked
}

popupBtn.onclick = function () {
    changePopup(false)
    clearData()
    showPopup()
}

cross.onclick = function () {
    closePopup()
}

document.onclick = function(e){

    if (popupContent.classList.contains('popup_active') && e.target !== popupContent && !popupContent.contains(e.target)) {
        closePopup()
    }

    if (e.target.className !== '__select' && e.target.parentNode.className !== '__select') {
        selectContent.style.opacity = '0'
        selectSingle.setAttribute('data-state', '');
    }
};

const changePopup = (isSet) => {
    if (isSet) {
        popupContent.classList.add('popup__content_sent');
    } else {
        popupContent.classList.remove('popup__content_sent');
    }
}

popupSubmit.onclick = function (event) {
    const phone = document.getElementById('popup__phone');
    const email = document.getElementById('popup__email');
    const name = document.getElementById('popup__name');
    const message = document.getElementById('popup__message');
    const date = new Date()

    if (checkInputs(email, phone)) {
        sendEmail(phone.value, email.value, name.value, message.value, date)
        changePopup(true)
    }
}

const clearData = () => {
    const inps = document.querySelectorAll('.input');
    inps.forEach(inp => {
        inp.value = '';
    })

    document.getElementById('checkbox').checked = false

}

const errors = {
    phoneError: 'Укажите верный номер телефона',
    emailError: 'Укажите верный email',
    errorAll: 'Укажите верные данные'
}

const validate = (value, type) => {
    if (type === 'phone') {
        return /^((80|\+375)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{9}$/.test(value);
    } else if (type === 'email') {
        return value === '' || /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(value) ;
    }
}


const checkInputs = (email, phone) => {
    const error = document.querySelector('.popup__error');

    const isPhoneValid = validate(phone.value, 'phone')
    const isEmailValid = validate(email.value, 'email')

    if (isPhoneValid && isEmailValid) {
        error.style.display = 'none';
        phone.classList.remove('input_error');
        email.classList.remove('input_error');
        return true
    } else {
        error.style.display = 'block';
        if (!isPhoneValid && !isEmailValid) {
            phone.classList.add('input_error');
            email.classList.add('input_error');
            error.textContent = errors.errorAll
        } else if (!isEmailValid) {
            email.classList.add('input_error');
            phone.classList.remove('input_error');
            error.textContent = errors.emailError
        } else if (!isPhoneValid) {
            phone.classList.add('input_error');
            email.classList.remove('input_error');
            error.textContent = errors.phoneError
        }
        return false
    }

}


const selectWrapp = document.querySelector('.__select_wrapper');
const selectSingle = document.querySelector('.__select');
const selectSingle_title = selectSingle.querySelector('.__select__title');
const selectSingle_labels = selectSingle.querySelectorAll('.__select__label');
const selectContent = selectSingle.querySelector('.__select__content');

selectWrapp.addEventListener('click', () => {
    selectContent.style.opacity = '0'
    selectContent.style.display = 'none'
    if ('active' === selectSingle.getAttribute('data-state')) {
        selectSingle.setAttribute('data-state', '');
    } else {
        selectContent.style.opacity = '1'
        selectContent.style.display = 'flex'
        selectSingle.setAttribute('data-state', 'active');
    }
});


const generateSelectLabels = (text, target) => {
    selectSingle_labels.forEach(el => {
        if (el.textContent === target) {
            el.textContent = text
        }
    })
}

for (let i = 0; i < selectSingle_labels.length; i++) {
    selectSingle_labels[i].addEventListener('click', (evt) => {
        const text = selectSingle_title.textContent;
        selectSingle_title.textContent = evt.target.textContent;
        selectSingle.setAttribute('data-state', '');
        generateSelectLabels(text, evt.target.textContent);
        selectContent.style.opacity = '0'
        selectContent.style.display = 'none'
    });
}

const inputTel = document.getElementById('popup__phone')

inputTel.onfocus = function () {
    if (inputTel.value === '') {
        inputTel.value = '+';
    }
}

const sendEmail = (phone, email, name, message, date) => {
    emailjs.send("service_i9cwjf5","template_0bls41h",{
        from_name: "IT Vipo",
        phone: `phone: ${phone}`,
        email: `email: ${email}`,
        name: `name: ${name}`,
        message: `message: ${message}`,
        date: `${date}`
    }).then((result) => {
        console.log(result.text)
    });
    emailjs.send("service_i9cwjf5","template_k0rypbq",{
        from_name: "IT Vipo",
        phone: `phone: ${phone}`,
        email: `email: ${email}`,
        name: `name: ${name}`,
        message: `message: ${message}`,
        date: `${date}`
    }).then((result) => {
        console.log(result.text)
    });
}



